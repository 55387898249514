












import { MetaInfo } from 'vue-meta'
import { Component, Mixins } from 'vue-property-decorator'

import TicketForm from '@/components/forms/support/TicketForm.vue'
import NotifyMixin from '@/mixins/NotifyMixin'
import { TicketShortResource } from '@/store/types'

@Component({
  components: {
    TicketForm,
  },
})
export default class SupportNew extends Mixins(NotifyMixin) {
  private get breadcrumbs () {
    return [
      {
        name: 'Техподдержка',
        path: this.$route.name === 'manager.support.new' ? '/manager/support' : '/master/support',
      },
    ]
  }

  private handleSuccess (ticket: TicketShortResource) {
    this.$router.push({
      name: this.$route.name === 'manager.support.new' ? 'manager.support.item' : 'master.support.item',
      params: { ticketID: ticket.id.toString() },
    })
  }

  private metaInfo (): MetaInfo {
    return {
      title: 'Новое обращение',
    }
  }
}
